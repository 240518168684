<template>
    <div class="memod-tos">
        <div class="legal">
            <div class="container">
                <div class="header">
                    <h1>Terms of Service</h1>
                    <span>WELCOME TO MEMO’D!</span>
                </div>
                <p>PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY.  BY ACCESSING OR USING OUR SERVICES, YOU HEREBY ACCEPT AND AGREE TO BE BOUND BY THESE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE.  IT IS THE RESPONSIBILITY OF YOU, THE USER OR MEMBER, TO READ THE TERMS OF SERVICE BEFORE PROCEEDING TO USE ANY OF OUR SERVICES, INCLUDING BUT NOT LIMITED TO ALL FEATURES, SITES, AND CONTENT.</p>
                <div class="table-of-content">
                    <span>1. <a href="#1">Acceptance of Terms</a></span>
                    <span>2. <a href="#2">Definitions</a></span>
                    <span>3. <a href="#3">Description of Memo’d Services</a></span>
                    <span>4. <a href="#4">Access and Use of Services</a></span>
                    <span>5. <a href="#5">Intellectual Property; Propietary Rights</a></span>
                    <span>6. <a href="#6">User Content</a></span>
                    <span>7. <a href="#7">Copyright Policy</a></span>
                    <span>8. <a href="#8">Privacy Policy</a></span>
                    <span>9. <a href="#9">Account, Username, Password and Security</a></span>
                    <span>10. <a href="#10">Use and Storage General Practices</a></span>
                    <span>11. <a href="#11">Cancellation; Termination</a></span>
                    <span>12. <a href="#12">Links</a></span>
                    <span>13. <a href="#13">Indemnity</a></span>
                    <span>14. <a href="#14">Warranty Disclaimers</a></span>
                    <span>15. <a href="#15">Limitation of Liability</a></span>
                    <span>16. <a href="#16">Exclusions and Limitations</a></span>
                    <span>17. <a href="#17">Release</a></span>
                    <span>18. <a href="#18">Special Admonition Related to Financial Matters</a></span>
                    <span>19. <a href="#19">Third Party Beneficiaries</a></span>
                    <span>20. <a href="#20">Notice</a></span>
                    <span>21. <a href="#21">Dispute Resolution</a></span>
                    <span>22. <a href="#22">Statute of Limitations</a></span>
                    <span>23. <a href="#23">General Information</a></span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="terms-html" v-html="terms" />
        </div>
    </div>
</template>

<script>
import terms from "@assets/terms/tos.html";

export default {
    name: "MemodTos",
    data: () => ({ terms }),
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss">
.terms-html {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin-left: -20px;

    .category-title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 35px;
        font-weight: 600;
    }
}
</style>
